import styled from 'styled-components';
import { breakpoint } from '../../../styles/breakpoints';

export const CarouselWrapper = styled.div`
  position: relative;
  display: inline-block;
  left: 50%;
  width: 50%;
  top: -50%;
  ${breakpoint('tablet')`
    padding-top: 0em;
  `}

  ${breakpoint('mobile')`
    padding-top: 15em;
    margin-left: -17em;
    width: 80%;
  `}
`;

export const ImageWrapper = styled.div`
  padding-bottom: 10em;
  border-radius: 8px;
`;

export const CarouselCopy = styled.div`
  ${(props) => props.theme.typography.typeStyleRevxNeueText1}
  width: 94%;
  background: #fafafa;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  padding-bottom: 2em;
`;

export const Description = styled.p<{ top: string }>`
  ${(props) => props.theme.typography.typeStyleRevxNeueText1}
  color: ${(props) => props.theme.colors.black};
  position: relative;
  width: 540px;
  top: ${(props) => props.top};

  ${breakpoint('tablet')`
    top: -20em;
    width: 20em;    
  `}

  ${breakpoint('mobile')`
    top: -20em;
    width: 20em;    
  `}
`;
