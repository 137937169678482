import React from 'react';
import { testimonialsObject } from '../../../model/testimonialsObject';
import { ImageWrapper, CarouselCopy } from './Testimonials.css';
import Image from 'next/image';

const TestimonialsItem = (props: ITestimonialsItemProps) => {
  // @ts-ignore
  const truncate = (str: string, n: number, useWordBoundary: boolean) => {
    if (str.length <= n) {
      return str;
    }
    const subString = str.substr(0, n - 1); // the original check
    return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...';
  };
  return (
    <>
      <ImageWrapper>
        <Image
          layout="fixed"
          objectFit="initial"
          src={props.testimonial.thumbnail}
          alt={props.testimonial.author}
          width="250px"
          height="250px"
        />
      </ImageWrapper>
      <CarouselCopy>
        <h3>
          {props.testimonial.author}
          <br />
          {props.testimonial.jobTitle}
        </h3>
        {truncate(props.testimonial.content, 300, true)}.
      </CarouselCopy>
    </>
  );
};

interface ITestimonialsItemProps {
  testimonial: testimonialsObject;
}

export default TestimonialsItem;
