import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useRecoilValueLoadable } from 'recoil';
import TestimonialsItem from './TestimonialsItem';
import { getTestimonialsPosts } from '../../../recoil/selectors/testimonialsSelectors';
import { testimonialsObject } from '../../../model/testimonialsObject';
import { CarouselWrapper, Description } from './Testimonials.css';
import {
  ALinkButtonWrapper,
  ClientListCopyContent,
  Filler,
  HomePageTitle,
  ContentWrapper,
  TestimonialsHireMeMobileLinkButtonWrapper,
} from '../../pages/Home/HomePage.css';
import Link from 'next/link';

const Testimonials = () => {
  const testimonials = useRecoilValueLoadable(getTestimonialsPosts);
  const [data, setData] = useState([]);
  useEffect(() => {
    if (testimonials.state === 'hasValue') {
      const d = JSON.parse(JSON.stringify(testimonials.contents));
      setData(d);
    }
  }, [testimonials]);
  return (
    <>
      {data.length > 1 && (
        <>
          <ContentWrapper>
            <ClientListCopyContent>
              <HomePageTitle paddingTop="40%">100% Success Rate</HomePageTitle>
              <Description top="-2em">
                My clients choose me for my innovation, and return for my ability to successfully
                deliver on time and on budget. Many challenging projects, and no major hiccup.
              </Description>
              <ALinkButtonWrapper top="0em" width="10em">
                <Link href="/coaching">HIRE ME</Link>
              </ALinkButtonWrapper>
            </ClientListCopyContent>
          </ContentWrapper>
          <Filler />
          <CarouselWrapper>
            <Carousel
              showIndicators={false}
              showArrows={true}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              autoPlay={true}
              interval={6000}
            >
              {data.map((testimonial: testimonialsObject) => (
                <TestimonialsItem testimonial={testimonial} key={testimonial.author} />
              ))}
            </Carousel>
          </CarouselWrapper>
          <TestimonialsHireMeMobileLinkButtonWrapper>
            <Link href="/coding">HIRE ME</Link>
          </TestimonialsHireMeMobileLinkButtonWrapper>
        </>
      )}
    </>
  );
};
export default Testimonials;
