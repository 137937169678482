import { useState, useEffect, useRef } from 'react';
import breakpoints from '../styles/breakpoints';
import isSSR from '../utils/isSSR';

export function useIsMobile() {
  const isServer = isSSR();
  const media = useRef(isServer ? null : window.matchMedia(breakpoints.mobile));
  const [matches, setMatches] = useState(media.current?.matches);

  useEffect(() => {
    const listener = () => {
      setMatches(media.current?.matches);
    };
    // addEventListener 'change' is not working, must use deprecated addListener
    media.current?.addListener(listener);
    return () => media.current?.removeListener(listener);
  }, []);

  return matches;
}
